<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="320px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<el-form :model="dataForm" :rules="dataRule" label-position="top" ref="dataForm">
			<el-form-item label="分组名称" prop="name">
				<el-input size="small" v-model="dataForm.name" placeholder="分组名称" maxlength="15"></el-input>
			</el-form-item>
			<el-form-item label="排序" prop="sort">
				<el-input size="small" v-model="dataForm.sort" placeholder="排序" maxlength="10"></el-input>
			</el-form-item>
        </el-form>
		<span slot="footer" class="dialog-footer">
			<el-button class="submit-btn" @click="submit">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
export default {
	data() {
		return {
			title: '新增分组',
			visible: false,
			submitUrl: 'dealerGroupAdd',
			dataForm: {
				name: '',
				sort: '',
			},
			dataRule: {
				name: [
					{required: true,message: '分组名称不能为空', trigger: "blur"}
				],
				sort: [
					{required: true,message: '排序不能为空', trigger: "blur"},
					{pattern: /^[0-9]*$/, message: '排序必须为整数'}
				]
			}
		}
	},
	methods: {
		init(type,data) {
			this.visible = true;
			this.$nextTick(() => {
				this.$refs.dataForm.resetFields();
				if(type == 'add') {
					this.title = '新增分组';
					delete this.dataForm.id;
					this.submitUrl = 'dealerGroupAdd';
				}
				else if(type == 'edit') {
					this.title = '编辑分组';
					this.submitUrl = 'dealerGroupUpdate';
					this.dataForm.id = data.id;
					this.dataForm.name = data.name;
					this.dataForm.sort = data.sort;
				}
			})
		},
		submit:debounce(function() {
			if(this.visible == false) {
				return
			}
			this.$refs['dataForm'].validate(valid => {
				if (valid) {
					let that = this;
					that.$request.post(
						that.submitUrl,
						true,
						that.dataForm,
						function (r) {
							if (r.code == "0") {
								that.visible = false;
								that.$emit('getDepartTree');
								that.$message.success('保存成功');
							} else {
								that.$message.error(r.msg);
							}
						}
					);
				} else {
					return false;
				}
			});
		},1000)
	}
}
</script>
<style lang="scss" scoped>
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.el-form-item{
	margin-bottom: 20px;
	.el-select{
		width: 100%;
	}
}
.submit-btn{
	width: 100%;
	background-color: #117FFC;
	color: #fff;
}
.role-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.role-dialog /deep/ .el-dialog__body{
	padding: 0 20px 20px !important;
}
.role-dialog /deep/ .el-form-item__label{
	padding: 0 !important;
	line-height: 24px !important;
}
.role-dialog /deep/ .el-dialog__footer{
	padding: 0px 20px 40px !important;
}
.flex{
	display: flex;
}
.flex-1{
	flex: 1;
	box-sizing: border-box;
	padding-right: 15px;
}
</style>
